<template>
  <div id="testVideo">
    <div id="videoBox"></div>

    <!--crossOrigin="anonymous"-->
    <!--playsinline-->
    <video
      id="video"
      src="https://vravo-1258661974.cos.accelerate.myqcloud.com/todo%20tomo_main%20ver1~1920i.mp4"
      loop
      muted
      crossOrigin="anonymous"
      playsinline
      style="display:none"
    >
    </video>
  </div>
</template>

<script>
import {
  Scene,
  PerspectiveCamera,
  VideoTexture,
  Color,
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
  WebGLRenderer,
  DoubleSide
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { StereoEffect } from 'three/examples/jsm/effects/StereoEffect.js';
// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';//沉浸式控制器
// import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { WEBGL } from 'three/examples/jsm/WebGL.js';
import { mapGetters } from 'vuex';
export default {
  name: 'TestVideo',
  components: {},
  data () {
    return {
      threeInfo: {
        container: null,
        video: null,
        scene: null,
        camera: null
      }
    };
  },
  computed: {
    ...mapGetters(['htmlWidth'])
  },
  created () {
  },
  mounted () {
    this.init();

    this.onResize();
  },
  watch: {

    /* 监听窗口大小变化 */
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {
    init () {
      // scene场景、camera相机和renderer渲染器
      const container = document.getElementById('videoBox');
      const video = document.getElementById('video');
      // scene场景
      const scene = new Scene();
      scene.background = new Color(0x101010);

      /*
      * camera相机
      * PerspectiveCamera透视摄像机(视野角度,长宽比,近截面near,远截面far)
      * */
      const camera = new PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000);
      // Layers 对象为 Object3D 分配 1个到 32 个图层,从 0 到 31 编号标记
      // enable增加图层对象与参数指定图层的对应关系。
      camera.layers.enable(1); // 在没有立体感时,渲染左视图
      const controls = new OrbitControls(camera, container);
      controls.target.set(0, 1.6, 0);
      controls.update();
      // renderer渲染器
      const renderer = new WebGLRenderer();

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.xr.enabled = true;
      // 创建会话时其原始位置位于查看者位置附近的跟踪空间
      /*
      * 文档：https://www.mifengjc.com/api/XRReferenceSpaceType.html
      * local 参考空间会尝试使原点相对于环境保持稳定
      * local-floor 与 local 类型类似，不同之处在于，起始位置放置在安全的位置
      * unbounded 允许用户完全自由移动的跟踪空间，可能距离他们的原点很远
      * viewer 跟踪空间，其原始位置跟踪查看者的位置和方向
      * */
      renderer.xr.setReferenceSpaceType('local');
      // let effect = new StereoEffect(renderer);

      /*
      * 创建一个使用视频来作为贴图的纹理对象
      * VideoTexture(
      *   video : Video, Video元素
      *   mapping : Constant, 映射模式
      *   wrapS : Constant, 水平方向上纹理的包裹方式
      *   wrapT : Constant, 垂直方向上纹理的包裹方式
       *  magFilter : Constant, 放大滤镜 当一个纹素覆盖大于一个像素时，贴图将如何采样
       *  minFilter : Constant, 缩小滤镜 当一个纹素覆盖小于一个像素时，贴图将如何采样
       *  format : Constant, 格式
       *  type : Constant, 类型
       *  anisotropy : VideoTextureNumber 沿着轴，通过具有最高纹素密度的像素的采样数。 默认情况下，这个值为1。设置一个较高的值将会比基本的mipmap产生更清晰的效果，代价是需要使用更多纹理样本。 使用renderer.getMaxAnisotropy() 来查询GPU中各向异性的最大有效值；这个值通常是2的幂。
       * )
      * */
      const texture = new VideoTexture(video);

      // video
      container.addEventListener('click', function () {
        video.play();
      });
      container.appendChild(renderer.domElement);
      container.appendChild(this.createXRButton(renderer, container));
      console.log(renderer);
      this.threeInfo = {
        container,
        video,
        scene,
        camera,
        controls,
        // effect,
        renderer,
        texture
      };
      // left
      this.addGeometryScene(0, 1);
      // right
      this.addGeometryScene(0.5, 2);
      if (WEBGL.isWebGLAvailable()) {
        // 启动
        this.animate();
      } else {
        const warning = WEBGL.getWebGLErrorMessage();
        container.appendChild(warning);
      }
      this.$emit('onInit', this);
    },
    // 增加视图
    addGeometryScene (addNum, zIndex) {
      const { scene, texture } = this.threeInfo;
      // geometry型状 几何体
      /*
      * 生成球体
      * SphereGeometry(
      *   radius : Float, 球体半径，默认为1
      *   widthSegments : Integer, 水平分段数（沿着经线分段），最小值为3，默认值为32。
      *   heightSegments : Integer, 垂直分段数（沿着纬线分段），最小值为2，默认值为16。
      *   phiStart : Float, 指定水平（经线）起始角度，默认值为0
      *   phiLength : Float, 指定水平（经线）扫描角度的大小，默认值为 Math.PI * 2
      *   thetaStart : Float, 指定垂直（纬线）起始角度，默认值为0
      *   thetaLength : Float 指定垂直（纬线）扫描角度大小，默认值为 Math.PI
      * )
      * */
      const geometry = new SphereGeometry(500, 60, 40, -Math.PI / 2);
      // material材质 内容、颜色等
      const material = new MeshBasicMaterial({ map: texture });
      material.side = DoubleSide;
      geometry.scale(-1, 1, 1);
      const uvs = geometry.attributes.uv.array;
      for (let i = 0; i < uvs.length; i += 2) {
        uvs[i] *= 0.5;
        uvs[i] += addNum;
      }
      // Mesh网格 网格包含一个几何体以及作用在此几何体上的材质
      const mesh = new Mesh(geometry, material);
      mesh.rotation.y = -Math.PI / 2;
      // 删除图层对象已有的所有对应关系，增加与参数指定的图层的对应关系。
      mesh.layers.set(zIndex);
      scene.add(mesh);
    },
    // 启动
    animate () {
      const { camera, scene, renderer } = this.threeInfo;
      renderer.setAnimationLoop(() => {
        // effect.render(scene, camera);
        renderer.render(scene, camera);
      });
    },
    createXRButton (renderer, container) {
      const button = document.createElement('button');

      function showEnterVR (/* device*/) {

        let currentSession = null;

        async function onSessionStarted (session) {

          session.addEventListener('end', onSessionEnded);

          await renderer.xr.setSession(session);
          button.textContent = 'EXIT VR';

          currentSession = session;

        }

        function onSessionEnded (/* event*/) {
          console.log(77777);
          currentSession.removeEventListener('end', onSessionEnded);

          button.textContent = 'ENTER VR';

          currentSession = null;

        }

        //

        button.style.display = '';

        button.style.cursor = 'pointer';
        button.style.left = 'calc(50% - 50px)';
        button.style.width = '100px';

        button.textContent = 'ENTER VR';

        button.onmouseenter = function () {

          button.style.opacity = '1.0';

        };

        button.onmouseleave = function () {

          button.style.opacity = '0.5';

        };

        button.onclick = function () {

          if (currentSession === null) {

            // WebXR's requestReferenceSpace only works if the corresponding feature
            // was requested at session creation time. For simplicity, just ask for
            // the interesting ones as optional features, but be aware that the
            // requestReferenceSpace call will fail if it turns out to be unavailable.
            // ('local' is always available for immersive sessions and doesn't need to
            // be requested separately.)
            const sessionInit = {
              optionalFeatures: ['dom-overlay'],
              domOverlay: {
                root: container
              }
            };
            // const sessionInit = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'layers'] };
            navigator.xr.requestSession('immersive-vr', sessionInit).then(onSessionStarted);

          } else {

            currentSession.end();

          }

        };

      }

      function disableButton () {

        button.style.display = '';

        button.style.cursor = 'auto';
        button.style.left = 'calc(50% - 75px)';
        button.style.width = '150px';

        button.onmouseenter = null;
        button.onmouseleave = null;

        button.onclick = null;

      }

      function showWebXRNotFound () {

        disableButton();

        button.textContent = 'VR NOT SUPPORTED';

      }

      function stylizeElement (element) {

        element.style.position = 'absolute';
        element.style.bottom = '20px';
        element.style.padding = '12px 6px';
        element.style.border = '1px solid #fff';
        element.style.borderRadius = '4px';
        element.style.background = 'rgba(0,0,0,0.1)';
        element.style.color = '#fff';
        element.style.font = 'normal 13px sans-serif';
        element.style.textAlign = 'center';
        element.style.opacity = '0.5';
        element.style.outline = 'none';
        element.style.zIndex = '999';

      }

      if ('xr' in navigator) {

        button.id = 'VRButton';
        button.style.display = 'none';

        stylizeElement(button);

        navigator.xr.isSessionSupported('immersive-vr').then(function (supported) {

          supported ? showEnterVR() : showWebXRNotFound();

        });

        return button;

      }

      const message = document.createElement('a');

      if (window.isSecureContext === false) {

        message.href = document.location.href.replace(/^http:/, 'https:');
        message.innerHTML = 'WEBXR NEEDS HTTPS'; // TODO Improve message

      } else {

        message.href = 'https://immersiveweb.dev/';
        message.innerHTML = 'WEBXR NOT AVAILABLE';

      }

      message.style.left = 'calc(50% - 90px)';
      message.style.width = '180px';
      message.style.textDecoration = 'none';

      stylizeElement(message);

      return message;


    },
    onResize () {
      const { camera, renderer } = this.threeInfo;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);

    }
  }
};
</script>

<style scoped lang="less">
#testVideo{
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*overflow: hidden;*/
  /*z-index: 9999999;*/
  #videoBox{
    position: fixed;
    width: 100%;
    height: 100%;
  }
}
</style>
